<template>
  <div class="mm-main-page">
    <slot />
    <GTM />
    <ModalsContainer />
  </div>
</template>

<script lang="ts" setup>
import GTM from 'shared/components/GTM.vue';
import { useMainTitle } from 'composables/useMainTitle';
import { useServerSsoAuth } from 'composables/useAuthHelper';
import { useRefreshBasketData } from 'composables/useRefreshBasketData';
import { useCheckInternalUser } from 'shared/composables/useCheckInternalUser';
import setAuthCookieHeaders from '../composables/setAuthCookieHeaders';

setAuthCookieHeaders();
await useDomainAuth();

await useServerSsoAuth();

useMainTitle();

await useSetServerUserInfo();
useSetClientUserInfo();
useRefreshBasketData();


useCheckInternalUser();
</script>

<style lang="scss" scoped>
.mm-main-page {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  &.overflow {
    overflow: hidden;
  }
}
</style>
